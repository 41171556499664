import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';



function StartConsoleLog(...args) {
    console.log(...args);
  }
    StartConsoleLog('Utilewebsites.nl 360 Plugin voor benderspete.com');

    let deviceOrientationActive = false;
let orientationOffset = new THREE.Quaternion();
let initialSensorQuaternion = new THREE.Quaternion();



    let initialSensorRotation = new THREE.Euler(); // Initial rotation from the sensor

    init();



    function init() {
    const viewer = document.getElementById('utile360-viewer');

   
    if (!viewer) {
        console.error('Error: The element with id "utile360-viewer" does not exist.');
        return;
    }


  
    const today = new Date();
    const currentDay = today.toLocaleString('en-US', { weekday: 'long' });
    const currentTime = today.getHours();
    const currentPeriod = (currentTime >= 6 && currentTime < 12) ? 'Morning' :
                          (currentTime >= 12 && currentTime < 18) ? 'Afternoon' :
                          (currentTime >= 18 && currentTime < 24) ? 'Evening' : 'Night';

   /* if (!Utile360Data.activeDays.includes(currentDay) || !Utile360Data.activeTimes.includes(currentPeriod)) {
        return;
    }*/

// Controleer of Utile360Data.light gedefinieerd is en gebruik anders de standaardwaarde van 0.5
let lightIntensity = 0.5;
let backgroundMove = 0;
let currentBackground = null; // Variabele om de huidige achtergrond bij te houden

// Functie om een willekeurige achtergrondobject te kiezen
function getRandomBackground() {
    if (Utile360Data && Array.isArray(Utile360Data.backgroundImages) && Utile360Data.backgroundImages.length > 0) {
        let randomIndex;
        let randomBackground;

        do {
            randomIndex = Math.floor(Math.random() * Utile360Data.backgroundImages.length);
            randomBackground = Utile360Data.backgroundImages[randomIndex];
        } while (randomBackground === currentBackground);

        // Update de global variables
        backgroundMove = randomBackground.background_move;
        lightIntensity = typeof randomBackground.light !== 'undefined' ? parseFloat(randomBackground.light) : 0.5;
        currentBackground = randomBackground; // Werk de huidige achtergrond bij

        return randomBackground;
    } else {
        console.error('Geen achtergrondafbeeldingen gevonden in Utile360Data');
        return null;
    }
}
// Functie om de achtergrond, background_move en light in te stellen
function setBackground() {
    const randomBackground = getRandomBackground();
    if (randomBackground) {
        textureLoader.load(randomBackground.background_image, function(texture) {
            sphereMaterial.map = texture;
            sphereMaterial.needsUpdate = true; // Forceer update van het materiaal
        });

        backgroundMove = randomBackground.background_move;
        lightIntensity = typeof randomBackground.light !== 'undefined' ? parseFloat(randomBackground.light) : 0.5;

        console.log(randomBackground.background_image,backgroundMove, lightIntensity);

        updateLightIntensity(lightIntensity);
    }
}


    
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    viewer.appendChild(renderer.domElement);
    let manualRotationQuaternion = new THREE.Quaternion().copy(camera.quaternion);

    // Load the 360-degree background texture
    const textureLoader = new THREE.TextureLoader();
    const sphereGeometry = new THREE.SphereGeometry(500, 60, 40);
    sphereGeometry.scale(-1, 1, 1);

    
    const sphereMaterial = new THREE.MeshStandardMaterial({
        map: textureLoader.load(getRandomBackground().background_image),
        roughness: 0.1,
        metalness: 0.5,
        envMap: textureLoader.load(Utile360Data.pluginUrl + 'envMap.png')
    });

    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    scene.add(sphere);

// Stel een interval in om de achtergrond elke minuut te veranderen
setInterval(setBackground, 60000); // 60000 ms = 1 minuut

    // Create a container for the panels
    const panelContainer = document.createElement('div');
    panelContainer.id = 'panel-container';
    panelContainer.style.display = 'none'; 
    panelContainer.classList.add('hidden');
    document.body.appendChild(panelContainer);


    







   
    const toggleButton = document.createElement('button');
    toggleButton.id = 'toggle-panel-btn';
    //toggleButton.innerHTML = '<i class="fas fa-th"></i>';
    toggleButton.innerHTML = '<img src="'+ Utile360Data.pluginUrl+'logo-bas.png" alt="Toggle" style="width: 70px; height: 70px;">'; // Voeg hier het pad naar je afbeelding toe
    toggleButton.style.cssText = 'position: fixed; top: 40px; left: 50%; transform: translateX(-50%); background-color: transparent; color:transparent; border: none; padding: 10px; border-radius: 50%; cursor: pointer; font-size: 18px; display: flex; align-items: center; justify-content: center;';
    document.body.appendChild(toggleButton);

// Create the 360 indicator button
const indicatorButton = document.createElement('button');
indicatorButton.id = 'indicator-btn';
indicatorButton.innerHTML = '<i class="fas fa-arrows-alt"></i>';
indicatorButton.style.cssText = 'position: fixed; bottom: 10px; right: 10px; background-color: rgba(0, 0, 0, 0.5); color: white; border: none; padding: 15px; border-radius: 50%; cursor: default; font-size: 24px; display: flex; align-items: center; justify-content: center;';
document.body.appendChild(indicatorButton);

// Create the tooltip
const tooltip = document.createElement('div');
tooltip.id = 'tooltip';
tooltip.innerHTML = 'Move through the 360 degrees <br/> Got lost? Refresh!';
tooltip.style.cssText = 'position: fixed; bottom: 60px; right: 10px; background-color: rgba(0, 0, 0, 0.8); color: white; padding: 10px; border-radius: 5px; opacity: 0; transition: opacity 0.3s;';
document.body.appendChild(tooltip);

// Show tooltip on hover
indicatorButton.addEventListener('mouseover', () => {
    tooltip.style.opacity = '1';
});

// Hide tooltip when not hovering
indicatorButton.addEventListener('mouseout', () => {
    tooltip.style.opacity = '0';
});
    // Sorteer de objecten op positie
    Utile360Data.objects.sort((a, b) => a.positie - b.positie);

    // Dynamisch de panelen aanmaken
    Utile360Data.objects.forEach(obj => {
    
            if (obj.type === 'front') {
        const panel = document.createElement('div');
        panel.classList.add('info-panel');
        panel.dataset.infoContent = obj.infoContent; // Zet de infoContent als dataset
        
        panel.style.setProperty('--panel-bg-color', obj.panelBgColor);
        panel.style.setProperty('--panel-text-color', obj.panelTextColor);
        panel.style.setProperty('--panel-height', obj.height);
        panel.style.setProperty('--panel-width', obj.width);

       
            const imgContainer = document.createElement('div');
            imgContainer.className = 'zoom-on-hover';
            imgContainer.style.position = 'relative';
    
            const img = document.createElement('img');
            img.src = obj.image;
            imgContainer.appendChild(img);
    
            if (obj.text) {
                const textOverlay = document.createElement('div');
                textOverlay.className = 'text-overlay';
                textOverlay.style.color = obj.textColor;
                textOverlay.innerHTML = obj.text;
                imgContainer.appendChild(textOverlay);
            }
    
            panel.appendChild(imgContainer);
    
            // Add hover event listeners to the image container
            imgContainer.addEventListener('mouseover', () => {
                imgContainer.style.zIndex = '9999'; // Bring the image container to the front on hover
            });
            imgContainer.addEventListener('mouseout', () => {
                imgContainer.style.zIndex = '0'; // Send the image container to the back when the mouse leaves
            });
      

      
            if (obj.infoBoxStyle === 'directlink') {
                panel.addEventListener('click', function() {
                    window.open(obj.infoContent, '_blank'); // Open the link in a new tab
                });
            } else {
                panel.addEventListener('click', function() {
                    console.log(obj.infoBoxBgColor);
                    openInfoBox(obj.title, panel.dataset.infoContent, obj.infoBoxBgColor, obj.infoBoxTextColor, obj.infoBoxStyle);
                });
            }

        panelContainer.appendChild(panel);
    }
    });

    // add objects to 360 field

    const clickableObjects = [];
    let testObjectSet = true;
    
    const minSize = 30; // Minimale waarde voor breedte en hoogte
    const maxSize = 100; // Maximale waarde voor breedte en hoogte
    const defaultSize = 50; // Standaard waarde als obj.size niet aanwezig of nul is
    const scaleFactor = 0.02; // Schaalfactor voor de objecten (500 keer kleiner)
    
    Utile360Data.objects.forEach(obj => {
        if (obj.type === 'veld') {
            console.log('trigger in veld');
            console.log(obj);
    
            // Load the image as a texture
            textureLoader.load(obj.image, function (texture) {
                // Set the size based on obj.size with boundaries
                let size = defaultSize;
                if (obj.size) {
                    size = Math.max(minSize, Math.min(maxSize, obj.size));
                }
                const standardWidth = size;
                const standardHeight = size;
    
                const geometry = new THREE.PlaneGeometry(standardWidth, standardHeight);
    
                // Create a material with the loaded texture and enable transparency
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                    side: THREE.DoubleSide,
                    transparent: true,
                    alphaTest: 0.5 // Adjust this value as needed
                });
    
                // Create a mesh with the geometry and material
                const mesh = new THREE.Mesh(geometry, material);
    
  // Ensure the position values are numbers
  const v_x = (parseFloat(obj.v_x)/50) || 0;
  const v_y = (parseFloat(obj.v_y)/50) || 0;
  const adjusted_y_z = v_y > 0 ? -v_y : Math.abs(v_y); // Omkeer de waarde als deze positief is, anders neem de absolute waarde
  const v_z = ((parseFloat(obj.v_z))/50 || 0) + 0; // Pas de z-positie aan

  console.log(`Setting position: x=${v_x}, y=${adjusted_y_z}, z=${v_z}`);

          // Stel de schaal van de mesh in
          mesh.scale.set(scaleFactor, scaleFactor, scaleFactor);

  // Set the mesh position
  if (!testObjectSet) {
      mesh.position.set(-100, 50, 200); // Adjust values as needed
      testObjectSet = true;
  } else {
      mesh.position.set(v_x, adjusted_y_z, v_z);
  }
                // Add the mesh as a child of the sphere
                sphere.add(mesh);
    
                // Add the mesh to the list of clickable objects
                clickableObjects.push({ mesh: mesh, data: obj });
            });
        }
    });
    
    


        // Raycaster for detecting clicks on objects
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
    
        function onMouseClick(event) {
            // Calculate mouse position in normalized device coordinates
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        
            // Update the raycaster with the camera and mouse position
            raycaster.setFromCamera(mouse, camera);
        
            // Calculate objects intersecting the picking ray
            const intersects = raycaster.intersectObjects(clickableObjects.map(o => o.mesh));
        
            if (intersects.length > 0) {
                const intersected = intersects[0];
                const objData = clickableObjects.find(o => o.mesh === intersected.object).data;
        
                if (objData.infoBoxStyle === 'directlink') {
                    // Open the link in a new tab
                    window.open(objData.infoContent, '_blank');
                } else {
                    // Open the info box
                    openInfoBox(objData.title, objData.infoContent, objData.infoBoxBgColor, objData.infoBoxTextColor, objData.infoBoxStyle);
                }
            }
        }
        
    
        window.addEventListener('click', onMouseClick, false);
  
    

// Create lights and add them to the scene
const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
directionalLight.position.set(0, 1, 0);
scene.add(directionalLight);

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

// Function to update light intensity
function updateLightIntensity(intensity) {
    directionalLight.intensity = intensity;
    ambientLight.intensity = intensity;
}



// Update light intensity
updateLightIntensity(lightIntensity);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = false;
    controls.zoomSpeed = 8;
    controls.minDistance = 0;
    controls.maxDistance = 500;
    controls.target.set(0, 0, 0);



    controls.update();

    camera.position.set(0, 0, 10);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    // Variables to keep track of mouse movement
    let isMouseDown = false;
    let previousMousePosition = {
        x: 0,
        y: 0
    };

    controls.addEventListener('change', function () {

        //get the current camera rotation
        let currentRotation = camera.rotation;
        console.log('Current Camera Rotation:', {   
            x: THREE.MathUtils.radToDeg(currentRotation.x).toFixed(2),
            y: THREE.MathUtils.radToDeg(currentRotation.y).toFixed(2),
            z: THREE.MathUtils.radToDeg(currentRotation.z).toFixed(2)   

    
    });
});


let manualControlActive = false; // Voeg een variabele toe om bij te houden of handmatige bediening actief is

// Voeg een event listener toe voor het starten van handmatige bediening
controls.addEventListener('start', function () {

    deactivateDeviceOrientation(); // Schakel device orientation uit tijdens handmatige bediening
});

controls.addEventListener('end', function () {
    // Na het stoppen met handmatige bediening, stel de huidige camera quaternion in als nieuwe basis en schakel device orientation opnieuw in
    manualRotationQuaternion.copy(camera.quaternion);

    // We beschouwen de huidige camera quaternion als de nieuwe referentie voor de sensorrotatie
    initialSensorQuaternion.identity(); // Reset de initialSensorQuaternion naar de eenheidsquaternion
    orientationOffset.copy(camera.quaternion); // Stel de huidige camera quaternion in als nieuwe offset

    console.log('Handmatige rotatie, nieuwe basis:', {
        x: THREE.MathUtils.radToDeg(camera.rotation.x).toFixed(2),
        y: THREE.MathUtils.radToDeg(camera.rotation.y).toFixed(2),
        z: THREE.MathUtils.radToDeg(camera.rotation.z).toFixed(2)
    });

    // Wacht een klein moment om mogelijke instabiliteit te vermijden voordat de sensor wordt geactiveerd
    setTimeout(() => {
        console.log('modus'+ manualControlActive);  
        if(manualControlActive== false){
        activateDeviceOrientation(); // Schakel de sensor opnieuw in
        }
    }, 100); // 500ms delay om eventuele jitter te vermijden
});


// Functie om de sensorButton div toe te voegen aan de HTML
function addSensorButton() {
    // Maak het div-element aan
    var sensorButton = document.createElement("div");

    // Stel de eigenschappen van het element in
    sensorButton.id = "sensorButton";
    sensorButton.innerHTML = "manual control ON";

    // Voeg de benodigde CSS-styling toe aan het element
    sensorButton.style.position = "absolute";
    sensorButton.style.display = "none";
    sensorButton.style.top = "0";
    sensorButton.style.width = "100%";
    sensorButton.style.background = "rgba(0, 0, 0, 0.7)";
    sensorButton.style.color = "white";
    sensorButton.style.padding = "10px";
    sensorButton.style.textAlign = "center";
    sensorButton.style.fontSize = "15px";
    sensorButton.style.cursor = "pointer";
    sensorButton.style.zIndex = "1000";

    // Voeg het element toe aan de body van de pagina
    document.body.appendChild(sensorButton);

    // Maak de sensorButton zichtbaar (optioneel, als je deze direct wilt laten zien)
   // sensorButton.style.display = "block";

    // Event listener om actie te ondernemen bij het klikken op de knop

}
// Create the toggle button
// Roep de functie aan om de knop toe te voegen en zichtbaar te maken
addSensorButton();

      // Als het een mobiel apparaat is, toon de sensorButton
      function isMobileOrTablet() {
        return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent) || 
               (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    
    if (isMobileOrTablet()) {
        document.getElementById('sensorButton').style.display = 'block';
    }
    



    document.getElementById('sensorButton').addEventListener('click', function() {
        if (deviceOrientationActive) {
            deactivateDeviceOrientation();
            manualControlActive = true; // Zet handmatige bediening aan wanneer de sensor wordt uitgeschakeld
        } else {
            activateDeviceOrientation();
            manualControlActive = false; // Zet handmatige bediening uit wanneer de sensor wordt ingeschakeld
        }
    });
   
function activateDeviceOrientation() {
    if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response === 'granted') {
            deviceOrientationActive = true;
                  // Stel de basisrotatie in op de huidige rotatie om dit als nieuw 0-punt te gebruiken
                    initialSensorRotation.set(0, 0, 0); // Reset initial sensor rotation
                    

            window.addEventListener('deviceorientation', onDeviceOrientation);
            document.getElementById('sensorButton').textContent = 'motion sensor ON';
          } else {
            alert('Toestemming voor apparaatrotatie niet verleend');
          }
        })
        .catch(console.error);
    } else if (window.DeviceOrientationEvent) {
      deviceOrientationActive = true;
    initialSensorRotation.set(0, 0, 0); // Reset initial sensor rotation
      window.addEventListener('deviceorientation', onDeviceOrientation);
      document.getElementById('sensorButton').textContent = 'motion sensor ON';
    } else {
      alert('Device Orientation wordt niet ondersteund op dit apparaat');
    }
  }

  function deactivateDeviceOrientation() {
    deviceOrientationActive = false;
    

    window.removeEventListener('deviceorientation', onDeviceOrientation);
    document.getElementById('sensorButton').textContent = 'manual control ON';
  }

/*
  let lerpFactor = 0.1; // Lerp factor voor zachtere overgangen

  
  function onDeviceOrientation(event) {
      if (deviceOrientationActive) {
          const alpha = THREE.MathUtils.degToRad(event.alpha || 0);
          const beta = THREE.MathUtils.degToRad(event.beta || 0);
          const gamma = THREE.MathUtils.degToRad(event.gamma || 0);
  
          let orientation = screen.orientation ? screen.orientation.angle : window.orientation;
  
          let adjustedAlpha = alpha;
          let adjustedBeta = THREE.MathUtils.clamp(beta, THREE.MathUtils.degToRad(-70), THREE.MathUtils.degToRad(70)); // Beperk de kanteling
          let adjustedGamma = gamma;
  
          switch (orientation) {
              case 90:
                  adjustedAlpha -= Math.PI / 2;
                  [adjustedBeta, adjustedGamma] = [gamma, -beta];
                  break;
              case -90:
              case 270:
                  adjustedAlpha += Math.PI / 2;
                  [adjustedBeta, adjustedGamma] = [-gamma, beta];
                  break;
              case 180:
                  adjustedAlpha += Math.PI;
                  [adjustedBeta, adjustedGamma] = [-beta, -gamma];
                  break;
          }
  
          // Maak een quaternion van de sensorgegevens
          let sensorQuaternion = new THREE.Quaternion();
          sensorQuaternion.setFromEuler(new THREE.Euler(adjustedBeta, adjustedAlpha, adjustedGamma, 'YXZ'));
  
          // Gebruik de eerste meting als basisrotatie
          if (initialSensorQuaternion.equals(new THREE.Quaternion())) {
              initialSensorQuaternion.copy(sensorQuaternion);
          }
  
          // Bereken de delta quaternion ten opzichte van de basis
          const deltaQuaternion = sensorQuaternion.clone().multiply(initialSensorQuaternion.clone().invert());
  
          // Combineer de huidige rotatie met de sensorrotatie
          let targetQuaternion = new THREE.Quaternion().copy(manualRotationQuaternion).multiply(deltaQuaternion);
  
          // Pas een smooth overgang toe naar de nieuwe rotatie
          camera.quaternion.slerp(targetQuaternion, lerpFactor);
  
          // Log de huidige rotatie voor debugging
          const combinedEuler = new THREE.Euler().setFromQuaternion(camera.quaternion, 'YXZ');
          console.log('Combined Camera Rotation:', {
              x: THREE.MathUtils.radToDeg(combinedEuler.x).toFixed(2),
              y: THREE.MathUtils.radToDeg(combinedEuler.y).toFixed(2),
              z: THREE.MathUtils.radToDeg(combinedEuler.z).toFixed(2)
          });
      }
  }
  
  */
let lerpFactor = 0.1; // Lerp factor voor zachtere overgangen

function onDeviceOrientation(event) {
    if (deviceOrientationActive) {
        const alpha = THREE.MathUtils.degToRad(event.alpha || 0);
        const beta = THREE.MathUtils.degToRad(event.beta || 0);
        const gamma = 0; // Zet gamma vast op 0 om roll-rotatie uit te schakelen

        let orientation = screen.orientation ? screen.orientation.angle : window.orientation;

        let adjustedAlpha = alpha;
        let adjustedBeta = THREE.MathUtils.clamp(beta, THREE.MathUtils.degToRad(-70), THREE.MathUtils.degToRad(70));
        let adjustedGamma = gamma;

        console.log('Device Orientation:', orientation);

        switch (orientation) {
            case -90: // Landschap, scherm naar links gedraaid
            console.log('Landschap, scherm naar links gedraaid');
                adjustedAlpha -= Math.PI / 2;
                adjustedBeta = gamma; // Wissel beta en gamma voor correcte pitch-beweging
                adjustedGamma = 0;    // Houd gamma op 0 om roll uit te schakelen
                break;
            case 90:
        /*    case -270: // Landschap, scherm naar rechts gedraaid
            console.log('Landschap, scherm naar rechts gedraaid');
                adjustedAlpha += Math.PI / 2;
                adjustedBeta = -gamma; // Wissel beta en gamma voor correcte pitch-beweging
                adjustedGamma = 0;      // Houd gamma op 0 om roll uit te schakelen
                break;*/
            case 180: // Portret, ondersteboven
                adjustedAlpha += Math.PI;
                adjustedBeta = -beta;   // Inverteer beta voor juiste pitch-beweging
                adjustedGamma = 0;      // Houd gamma op 0 om roll uit te schakelen
                break;
            default: // Portret, normale oriëntatie
                // adjustedGamma blijft 0, waardoor roll wordt uitgeschakeld
                break;
        }

        // Maak een quaternion van de sensorgegevens
        let sensorQuaternion = new THREE.Quaternion();
        sensorQuaternion.setFromEuler(new THREE.Euler(adjustedBeta, adjustedAlpha, adjustedGamma, 'YXZ'));

        // Gebruik de eerste meting als basisrotatie
        if (initialSensorQuaternion.equals(new THREE.Quaternion())) {
            initialSensorQuaternion.copy(sensorQuaternion);
        }

        // Bereken de delta quaternion ten opzichte van de basis
        const deltaQuaternion = sensorQuaternion.clone().multiply(initialSensorQuaternion.clone().invert());

        // Combineer de huidige rotatie met de sensorrotatie
        let targetQuaternion = new THREE.Quaternion().copy(manualRotationQuaternion).multiply(deltaQuaternion);

        // Pas een smooth overgang toe naar de nieuwe rotatie
        camera.quaternion.slerp(targetQuaternion, lerpFactor);

        // Log de huidige rotatie voor debugging
        const combinedEuler = new THREE.Euler().setFromQuaternion(camera.quaternion, 'YXZ');
        console.log('Combined Camera Rotation:', {
            x: THREE.MathUtils.radToDeg(combinedEuler.x).toFixed(2),
            y: THREE.MathUtils.radToDeg(combinedEuler.y).toFixed(2),
            z: THREE.MathUtils.radToDeg(combinedEuler.z).toFixed(2)
        });
    }
}

    
    

// Event listeners for mouse movements
document.addEventListener('mousedown', (event) => {
    isMouseDown = true;
    previousMousePosition = {
        x: event.clientX,
        y: event.clientY
    };
});

document.addEventListener('mouseup', () => {
    isMouseDown = false;
});

// Add event listener to the button
toggleButton.addEventListener('click', function() {
    if (panelContainer.classList.contains('hidden')) {
        panelContainer.style.display = 'grid';
        setTimeout(() => {
            panelContainer.style.opacity = '1';
            panelContainer.classList.remove('hidden');
        }, 10); // Small timeout to allow the display change to take effect
    } else {
        panelContainer.style.opacity = '0';
        setTimeout(() => {
            panelContainer.classList.add('hidden');
            panelContainer.style.display = 'none';
        }, 1000); // 1 second timeout to match the CSS transition
    }
});

document.addEventListener('mousemove', (event) => {
    if (isMouseDown) {
        const deltaMove = {
            x: event.clientX - previousMousePosition.x,
            y: event.clientY - previousMousePosition.y
        };

        const deltaRotationQuaternion = new THREE.Quaternion()
            .setFromEuler(new THREE.Euler(
                toRadians(deltaMove.y * 0.1),
                toRadians(deltaMove.x * 0.1),
                0,
                'XYZ'
            ));

        sphere.quaternion.multiplyQuaternions(deltaRotationQuaternion, sphere.quaternion);

        previousMousePosition = {
            x: event.clientX,
            y: event.clientY
        };
    }
});

// Helper function to convert degrees to radians
function toRadians(angle) {
    return angle * (Math.PI / 180);
}




    const rotationSpeed = 0.001;

    function animate() {
        requestAnimationFrame(animate);

        //console.log("move status");

        if (backgroundMove != 0) {
            sphere.rotation.y += rotationSpeed;
        }

       if (!deviceOrientationActive) {
            controls.update();
        }
    
        renderer.render(scene, camera);
    }

    animate();

    window.addEventListener('resize', function() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    }, false);

    function openInfoBox(title, content, bgColor, textColor, style) {
        const infoBox = document.getElementById('infoBox') || document.createElement('div');
        infoBox.id = 'infoBox';
        infoBox.innerHTML = `
        <div class="note-header">
            <button class="close-button round-button" onclick="closeInfoBox()">
                <i class="fas fa-times"></i>
            </button>
            <span class="note-title">${title ? `${title}` : ''}</span>
        </div>
        
        <hr>
        <div class="content">${content}</div>
        `;
        infoBox.style.backgroundColor = bgColor;
        infoBox.style.color = textColor;
        infoBox.style.display = 'block';
        infoBox.style.paddingTop = '20px'; // Add padding to the top
        infoBox.style.paddingBottom = '20px'; // Add padding to the bottom
        infoBox.style.maxHeight = 'calc(100vh - 100px)'; // Set a maximum height
        infoBox.style.overflowY = 'auto'; // Enable vertical scrolling

            // Add a style tag to change the color of <a> tags
    var styleTag = document.createElement('style');
    styleTag.innerHTML = `#infoBox a { color: ${textColor}; }`;
    document.head.appendChild(styleTag);   
    
        if (style === 'artistic') {
            infoBox.classList.add('artistic');
        } else {
            infoBox.classList.remove('artistic');
        }
    
        document.body.appendChild(infoBox);
    }


// Function to pause all YouTube videos
function pauseAllYouTubeVideos() {
    console.log('Pausing all YouTube videos');
    document.querySelectorAll('iframe').forEach(v => { v.src = v.src });
    document.querySelectorAll('video').forEach(v => { v.pause() });

}


window.closeInfoBox = function() {
    const infoBox = document.getElementById('infoBox');
    if (infoBox) {
        infoBox.style.display = 'none';
       
    }
    pauseAllYouTubeVideos() ;
};











};

document.addEventListener("DOMContentLoaded", function() {    
    const viewer = document.getElementById('utile360-viewer');

   
    if (!viewer) {
        console.error('Error: The element with id "utile360-viewer" does not exist.');
        return;
    }
    function adjustPanelContainer() {
        const panelContainer = document.getElementById("panel-container");
        const panels = document.querySelectorAll(".info-panel");
        const images = document.querySelectorAll(".info-panel img");
        let columns;

        // Determine the number of columns based on the window width
        if (window.innerWidth >= 1024) {
            columns = 4;
        } else if (window.innerWidth >= 768) {
            columns = 3;
        } else if (window.innerWidth >= 480) {
            columns = 3;
        } else {
            columns = 3;
        }

        // Set the grid template columns
        panelContainer.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;

        // Reset all panel heights to auto to get the natural height
        panels.forEach(panel => {
            panel.style.height = "auto";
        });

        // Force reflow to ensure styles are applied before measuring heights
        panels[0].offsetHeight;

        // Find the maximum height of all panels
        let maxHeight = 0;
        panels.forEach(panel => {
            const panelHeight = panel.offsetHeight;
            if (panelHeight > maxHeight) {
                maxHeight = panelHeight;
            }
        });

        // Set all panels to the maximum height
        panels.forEach(panel => {
            panel.style.height = 100 + "px";
        });

        // Adjust image styles
        images.forEach(img => {
            img.style.maxWidth = "100%";
            img.style.maxHeight = "100px";
            img.style.display = "block";
            img.style.margin = "0 auto";
        });

        // Calculate the number of rows and set row height
        const rows = Math.ceil(panels.length / columns);

// Assuming panelContainer is a reference to the div element
  
console.log('columns:', columns);
    
if(columns === 4){
    panelContainer.style.gridAutoRows = `${100 +10}px`;

} else {
        panelContainer.style.gridAutoRows = `${70 +10}px`;
}
          
    }

    // Initial adjustment
    adjustPanelContainer();

    

});
